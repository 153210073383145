export function DataURLtoFile(data: any, type: any) {
    // eslint-disable-next-line prefer-const
    let bytes = window.atob(data), n = bytes.length,u8arr = new Uint8Array(n)
    while(n--){
        u8arr[n]=bytes.charCodeAt(n)
    }
    return new Blob([u8arr], {type: type})
}

export function ExportExcel(data: any, type: any, name: string) {
    const _down = DataURLtoFile(data, type)
    const a = document.createElement('a')
    a.download = name
    a.href = window.URL.createObjectURL(data)
    a.click()
    document.body.appendChild(a)
}

export function base64DownloadFile(fileName: any, content: any) {
    let aLink = document.createElement("a");
    let blob = base64ToBlob(content); //new Blob([content]);

    let evt = document.createEvent("HTMLEvents");
    console.log("点击下载", evt);
    evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);

    // aLink.dispatchEvent(evt);
    aLink.click();
}

function base64ToBlob(code: any) {
    let parts = code;
    let contentType = parts[0].split(":")[1];
    let raw = window.atob(parts);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}
