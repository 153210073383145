








































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { Message } from "element-ui";
import { ExportExcel } from "@/utils/exportFile";
@Component({})
export default class AUser extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false
  // 总条数
  total = 0;
  // 弹窗
  channelDialog = false
  // 操作
  cooperationTime: any = []
  channel: any = {
    accSubject: '',
    checkoutOnline: 0,
    secret: 0,
    channelContactName: '',
    channelContactPhone: '',
    // channelContractDocuments: '',
    channelDescription: '',
    channelManager: '',
    channelManagerPhone: '',
    channelName: '',
    channelType: '',
    cooperationStartTime: '',
    cooperationStopTime: '',
    fileBody: '',
    fileName: ''
  }
  showL = false
  //上传
  fileList: any = [];
  formData = "";
  delFile() {
    this.fileList = [];
  }

  getBase64(file: any) {
    return new Promise(function(resolve, reject) {
      let reader = new FileReader();
      let imgResult: any = "";
      reader.readAsDataURL(file);
      reader.onload = function() {
        imgResult = reader.result;
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.onloadend = function() {
        resolve(imgResult);
      };
    });
  }

  protected handleExceed(res: any) {
    Message.error({
      message: res,
      duration: 5 * 1000,
    });
  }
  handleChange(file: any, fileList: any) {
    console.log(file);
    // this.fileList = fileList;
    // this.submitUpload()
    this.getBase64(file.raw).then((res: any) => {
      console.log(res.split(',')[1]);
      this.channel.fileName = file.name
      this.channel.fileBody = res.split(',')[1]
    })
  }
  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
  }
  handlePreview(file: any) {
    console.log(file);
    this.showL = true
    api
      .post('A12010', {path: this.channel.channelContractDocuments}).then(res => {
        Message.success('操作成功')
        ExportExcel(res.data, res.mime, this.documentsName);
      }).finally(() => {
        this.showL = false
    })
  }
  uploadFile(file: any) {
    (this.formData as any).append("file", file.file);
    this.submitUpload()
  }
  submitUpload() {
    let formData = new FormData();
    formData.append("file", (this.fileList[0] as any).raw);
  }

  javaConfig: any = {}
  getConf() {
    api.getConf().then((res) => {
      this.javaConfig = res
    })
  }

  isToDate(val: any) {
    var currentDate = Date.now();

    var compareDate = new Date(val).getTime();

    if (compareDate < currentDate) {
      return '过期'
    } else if (compareDate >= currentDate) {
      return '生效'
    }
  }


  // 跳转实时订单
  toOrder(row: any) {
    this.$router.push({
      path: '/order/realtimeOrder',
      query: {
        channelId: row.id
      }
    })
  }

  // 搜索条件
  search = {
    channelName: "",
    orderId: "",
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30107", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;

      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 页面跳转
  goTo(item: any, path: any) {
    if(path == 'accountCustomer') {
      this.$router.push({
        name: 'accountCustomer',
        query: {
          channelName: item.channelName,
          channelId: item.id
        }
      })
    } else if(path == 'channel_manage_product') {
      // 产品
      this.$router.push({
        name: 'channel_manage_product',
        query: {
          id: item.id,
          channelName: item.channelName,
          channelNumber: item.channelNumber,
          channelType: item.channelType
        }
      })
    } else if(path == 'channel_manage_user') {
      this.$router.push({
        name: 'channel_manage_user',
        query: {
          id: item.id,
          channelName: item.channelName,
          channelNumber: item.channelNumber,
          channelType: item.channelType,
          channelId: item.channelId
        }
      })
    }
  }

  // 修改
  documentsName = ''
  edit(item: any) {
    this.fileList = []
    this.cooperationTime = []
    this.channel = {...item}
    this.cooperationTime[0] = item.cooperationStartTime
    this.cooperationTime[1] = item.cooperationStopTime
    if(item.documentsName) {
      this.documentsName = item.documentsName
      this.fileList = [{name: item.documentsName}]
    }
    this.channelDialog = true
  }

  cancel() {
    this.fileList = []
    this.channelDialog = false

  }

  // 保存
  save() {
    this.channel.cooperationStartTime = this.cooperationTime[0]
    this.channel.cooperationStopTime = this.cooperationTime[1]
    this.btnloading = true
    api
      .post(this.channel.id ? "J30109" : "J30108", this.channel)
      .then((data) => {
        this.channelDialog = false
        this.searchTable()

        this.channel = {
          accSubject: '',
          checkoutOnline: 0,
          secret: 0,
          channelContactName: '',
          channelContactPhone: '',
          // channelContractDocuments: '',
          channelDescription: '',
          channelManager: '',
          channelName: '',
          channelType: '',
          cooperationStartTime: '',
          cooperationStopTime: ''
        }
      }).finally(() => {
        this.btnloading = false
    })
  }

  // 创建渠道
  addChannel() {
    this.fileList = []
    this.cooperationTime = []
    this.channel = {
      accSubject: '',
      checkoutOnline: 0,
      secret: 0,
      channelContactName: '',
      channelContactPhone: '',
      // channelContractDocuments: '',
      channelDescription: '',
      channelManager: '',
      channelName: '',
      channelType: '',
      cooperationStartTime: '',
      cooperationStopTime: ''
    }
    this.channelDialog = true
  }

  activated() {
    this.searchTable();
    this.getConf()
  }

}
